import { reviews } from '@getpopsure/private-constants';
import { reviewsBySite } from 'constants/reviews';
import Image, { StaticImageData } from 'next/image';
import { useTranslation } from 'next-i18next';
import trustPilotLogo from 'public/static/icons/reviewBadge/trustpilot-logo.svg';
import trustPilotLogoWhiteText from 'public/static/icons/reviewBadge/trustpilot-logo-white-text.svg';
import React from 'react';

import Rating from '../rating';
import styles from './style.module.scss';

const TrustpilotReviewBadge = ({
  displayReviews,
  whiteText = false,
}: {
  displayReviews?: boolean;
  whiteText?: boolean;
}) => {
  const { t } = useTranslation();
  const rating =
    Number(t('component.reviewbadge.trustpilot.rating')) ||
    reviewsBySite.trustpilot.rating;
  const numberOfReviews = Number(
    t('component.reviewbadge.trustpilot.numberOfReviews') ||
      reviewsBySite.trustpilot.numberOfReviews
  );
  const textColorClass = whiteText ? styles.whiteText : 'tc-grey-700';
  const logoImage: StaticImageData = whiteText
    ? trustPilotLogoWhiteText
    : trustPilotLogo;

  return (
    <a
      className={styles.container}
      href={reviews.trustpilot}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.innerContainer}>
        <div className={`mr8 p-h3 ${textColorClass}`}>{rating.toFixed(1)}</div>
        <div className="mr8">
          <Rating rating={rating} reviewType="trustpilot" />
        </div>
        {!displayReviews && (
          <Image
            className={styles.logoBig}
            src={logoImage}
            alt="trustpilot logo"
          />
        )}
      </div>
      {displayReviews && (
        <div className={`mt8 ${styles['inner-container']}`}>
          <div className="p-p--small tc-grey-600 fw-bold mr8">
            {numberOfReviews} <span className="fw-normal">reviews</span>
          </div>
          <Image
            className={styles.logoSmall}
            src={logoImage}
            alt="trustpilot logo"
          />
        </div>
      )}
    </a>
  );
};

export default TrustpilotReviewBadge;
