import { StaticImageData } from 'next/image';
import googleStarEmptyIcon from 'public/static/icons/reviewBadge/google-star-empty.svg';
import googleStarHalfIcon from 'public/static/icons/reviewBadge/google-star-half.svg';
import googleStarWholeIcon from 'public/static/icons/reviewBadge/google-star-whole.svg';
import trustPilotStarEmptyIcon from 'public/static/icons/reviewBadge/trustpilot-star-empty.svg';
import trustPilotStarHalfIcon from 'public/static/icons/reviewBadge/trustpilot-star-half.svg';
import trustPilotStarWholeIcon from 'public/static/icons/reviewBadge/trustpilot-star-whole.svg';

export type ReviewType = 'trustpilot' | 'google';

export const mapReviewStarIcon: {
  [K in ReviewType]: {
    wholeStarIcon: StaticImageData;
    halfStarIcon: StaticImageData;
    emptyStarIcon: StaticImageData;
  };
} = {
  trustpilot: {
    wholeStarIcon: trustPilotStarWholeIcon,
    halfStarIcon: trustPilotStarHalfIcon,
    emptyStarIcon: trustPilotStarEmptyIcon,
  },
  google: {
    wholeStarIcon: googleStarWholeIcon,
    halfStarIcon: googleStarHalfIcon,
    emptyStarIcon: googleStarEmptyIcon,
  },
};
