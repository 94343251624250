export const REVIEW_SITES = ['google', 'trustpilot'];

export type ReviewSite = typeof REVIEW_SITES[number];

type ReviewInfo = {
  siteName: 'Trustpilot' | 'Google reviews';
  rating: number;
  numberOfReviews: number;
};

// TODO: dynamically get trustpilot and google data
export const reviewsBySite: Record<ReviewSite, ReviewInfo> = {
  trustpilot: {
    siteName: 'Trustpilot',
    rating: 4.8,
    numberOfReviews: 380,
  },
  google: {
    siteName: 'Google reviews',
    rating: 4.8,
    numberOfReviews: 541,
  },
};
