import Image from 'next/image';
import React from 'react';

import { mapReviewStarIcon, ReviewType } from '../../../models/review';
import styles from './style.module.scss';
import { getNumberOfStars } from './util';

// Not ideal but since the array is static this is okay for now
/* eslint-disable react/no-array-index-key */
const Rating = ({
  rating,
  reviewType,
}: {
  rating: number;
  reviewType: ReviewType;
}) => {
  const { wholeStarIcon, halfStarIcon, emptyStarIcon } =
    mapReviewStarIcon[reviewType];
  const { wholeStars, halfStars, emptyStars } = getNumberOfStars(rating);
  return (
    <div className={styles.container}>
      {[...Array(wholeStars)].map((_, index) => (
        <Image
          key={index}
          className={styles.icon}
          src={wholeStarIcon}
          alt={`${reviewType} star`}
        />
      ))}
      {[...Array(halfStars)].map((_, index) => (
        <Image
          key={index}
          className={styles.icon}
          src={halfStarIcon}
          alt={`${reviewType} star`}
        />
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <Image
          key={index}
          className={styles.icon}
          src={emptyStarIcon}
          alt={`${reviewType} star`}
        />
      ))}
    </div>
  );
};

export default Rating;
