export const getResponsiveImgHeight = (
  newWidth: number,
  originalDimensions: { width: number; height: number } | null | undefined
): number | undefined => {
  if (
    originalDimensions &&
    originalDimensions.width !== 0 &&
    originalDimensions.height !== 0
  ) {
    const newHeight =
      (newWidth * originalDimensions.height) / originalDimensions.width;
    return Math.ceil(newHeight);
  }

  return undefined;
};
