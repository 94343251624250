import { reviews } from '@getpopsure/private-constants';
import { reviewsBySite } from 'constants/reviews';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import googleLogo from 'public/static/icons/reviewBadge/google-logo.svg';
import googleLogoWhite from 'public/static/icons/reviewBadge/google-logo-white.svg';
import React from 'react';

import Rating from '../rating';
import styles from './style.module.scss';

const GoogleReviewBadge = ({
  displayReviews,
  fontWhite,
}: {
  displayReviews?: boolean;
  fontWhite?: boolean;
}) => {
  const { t } = useTranslation();
  const rating =
    Number(t('component.reviewbadge.google.rating')) ||
    reviewsBySite.google.rating;

  const numberOfReviews = Number(
    t('component.reviewbadge.google.numberOfReviews') ||
      reviewsBySite.google.numberOfReviews
  );
  return (
    <a
      className={styles.container}
      href={reviews.google}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles['inner-container']}>
        <div
          className={`mr8 p-h3 ${
            fontWhite === true ? styles['white-font'] : 'tc-grey-700'
          }`}
        >
          {rating.toFixed(1)}
        </div>
        <div className="mr8">
          <Rating rating={rating} reviewType="google" />
        </div>
        {!displayReviews &&
          (fontWhite === true ? (
            <Image
              src={googleLogoWhite}
              alt="google logo"
              width={64}
              height={21}
            />
          ) : (
            <Image src={googleLogo} alt="google logo" width={64} height={21} />
          ))}
      </div>
      {displayReviews && (
        <div className={`mt16 ${styles['inner-container']}`}>
          <div className="p-p--small tc-grey-600 fw-bold mr8">
            {numberOfReviews} <span className="fw-normal">reviews</span>
          </div>
          {fontWhite === true ? (
            <Image
              src={googleLogoWhite}
              alt="google logo"
              width={64}
              height={21}
            />
          ) : (
            <Image src={googleLogo} alt="google logo" width={64} height={21} />
          )}
        </div>
      )}
    </a>
  );
};

export default GoogleReviewBadge;
