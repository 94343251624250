const TOTAL_STARS = 5;

const LOWER_HALF_STAR_LIMIT = 0.3;
const MAX_HALF_STAR_LIMIT = 0.7;

export const getNumberOfWholeStars = (rating: number): number => {
  const decimalValue = Number((rating % 1).toFixed(1));
  if (decimalValue > MAX_HALF_STAR_LIMIT) {
    return Math.ceil(rating);
  }
  return Math.floor(rating);
};

export const getNumberOfHalfStars = (rating: number): 0 | 1 => {
  const decimalValue = Number((rating % 1).toFixed(1));
  if (
    decimalValue >= LOWER_HALF_STAR_LIMIT &&
    decimalValue <= MAX_HALF_STAR_LIMIT
  ) {
    return 1;
  }
  return 0;
};

export const getNumberOfEmptyStars = (rating: number): number =>
  TOTAL_STARS - (getNumberOfWholeStars(rating) + getNumberOfHalfStars(rating));

export const getNumberOfStars = (rating: number) => ({
  wholeStars: getNumberOfWholeStars(rating),
  halfStars: getNumberOfHalfStars(rating),
  emptyStars: getNumberOfEmptyStars(rating),
});
